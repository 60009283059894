
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import {
  getComponent,
  getConfigEnv,
  getISOStringWithLocalOffset,
  sleep,
} from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import { mixins } from 'vue-class-component';
import GptMixin from './GptMixin';
import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';
import TasqItemMixin from '../tasqs/TasqItemMixin';
import { getNameByEmail } from '@/utils/users';
import uniqid from 'uniqid';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import { Debounce } from 'vue-debounce-decorator';
import TasqFeedbackMixin from '@/lib/mixins/TasqFeedbackMixin';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    GptRecommendation: () => getComponent('gpt/GptRecommendationV2'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestionV2'),
    WellSignalsSearch: () => getComponent('tasqs/WellSignalsSearch'),
    DateRangePicker,
    draggable,
    TodoList: () => getComponent('gpt/TodoList'),
  },
})
export default class TasqAIGroupItem extends mixins(GptMixin, TasqItemMixin) {
  updateSelectedWell() {
    gptModule.updateSelectedAiGroupWells(this.tasq.wellName);
    gptModule.updateSelectedAiGroupWellsObj({
      wellName: this.tasq.wellName,
      typeOfData: this.tasq.typeOfData || this.tasq.predictionType,
    });
  }

  gptTextQuery = '';
  // isClickedWell = false;

  @Watch('gptTextQuery')
  onGptQueryChange() {
    this.postGptComment(this.gptTextQuery);
  }

  hoveringResponseTitle = '';

  get recommendation() {
    return gptModule.recommendations;
  }

  isRecommendationLoaded = false;

  showRcommendation = false;

  async userOpensRecommendations(trackingID) {
    try {
      await axiosClient.put(
        `/response-store/recommendations-usage?tracking_id=${trackingID}`
      );
    } catch (error) {
      console.log(error);
    }
  }

  async saveRecommendationFeedback({
    comment,
    isGoodCatch,
    recommendationText,
    trackingID,
  }) {
    this.showRcommendation = false;
    const filterPayload = {
      operator: getConfigEnv('OPERATOR_LOWERCASED'),
      node_name: this.tasq.wellName,
      node_level: this.tasq.level,
      value_type: 'label',
      source_type: 'recommendation',
    };

    await axiosClient.post('/response-store/', {
      ...filterPayload,
      time: new Date().toISOString(),
      tracking_id: trackingID,
      data: {
        comment: comment,
        isGoodCatch: isGoodCatch,
        recommendationText: recommendationText,
      },
    });
  }

  get isClickedWell() {
    return (
      gptModule.SelectedGroupWellRow &&
      gptModule.SelectedGroupWellRow.length &&
      gptModule.SelectedGroupWellRow.includes(
        `${this.tasq.wellName}${
          this.tasq.typeOfData || this.tasq.predictionType
        }`
      )
    );
  }

  setSelectedGroupWellRow() {

    gptModule.resetRecommendations();
    this.gptTextQuery = '';

    if(this.isClickedWell){
      gptModule.setSelectedGroupWellRow(null)
      return
    }
    gptModule.setSelectedGroupWellRow(
      `${this.tasq.wellName}${this.tasq.typeOfData || this.tasq.predictionType}`
    );
  }

  get responses() {
    return [
      {
        title: 'Recommendation',
        color: 'bg-yellow004',
        date: 'Jan 3 - Feb 14',
        eventKey: 'show-recommendation',
        hover: false,
        hidden:
          !this.recommendation.length ||
          (this.recommendation.length && this.recommendation[0].length > 120),
      },
    ];
  }


  async getLastActions() {
    try {
      let operator = this.operatorDetails['operatorName'];

      if (operator === 'demo') {
        operator = 'pdce';
      }

      const { data } = await axiosClient.get('/meta/latest-actions', {
        params: {
          operator: operator,
        },
      });

      gptModule.setLastestWellsLastActions(data);
      await gptModule.getWellStatuses();
      await workflowModule.getWellStatuses();

    } catch (error) {}
  }

  async submitAudioEntry() {
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    axiosClient.post('/ai/comments', {
      body: this.gptTextQuery,
      wellMetaData: {
        nodeid: this.tasq.wellName,
        level: 'well',
        ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
      },
    });
    this.$tasqAlert({
      title: 'Success',
      message: 'Last Action Entry Submitted',
      type: 'success',
    });


    gptModule.resetRecommendations();
    this.gptTextQuery = '';
    gptModule.setSelectedGroupWellRow(null)
    await sleep(3500);
    this.getLastActions();
    this.$emit('close');

  }

  @Debounce(1000)
  async postGptComment(comment) {
    console.log('object');
    if (comment === '') {
      return;
    }
    gptModule.setGptQueryController('abort');
    // Create a new AbortController for the new set of requests
    gptModule.setGptQueryController('set');

    const { token } = gptModule.gptQueryController;

    const queryTime = new Date().getTime();
    gptModule.setQueryTime(queryTime);

    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    const endpoints = [
      {
        url: '/ai/recommendations',
        stream: true,
        key: 'Recommendations',
        useMetadata: true,
        canRemoveOperator: true,
      },
    ];

    let completedRequests = 0;
    tasqFeedbackModule.resetAllData();

    // Start all promises (backend requests) but don't wait for them to complete
    // Each request will set its value immediately upon completion
    endpoints.forEach(async (endpoint) => {
      try {
        gptModule[`reset${endpoint.key}`]([]);

        const metaData = {
          useAll: false,
          ...((this.operatorName === 'demo' ||
            endpoint.key === 'SeeSimilar' ||
            endpoint.key === 'LastActions') && {
            use_clean: true,
          }),
          wellMetaData: {
            ...((this.operatorName !== 'demo' ||
              !endpoint.canRemoveOperator) && {
              operator_name: operatorDetails['operatorName'],
            }),
            ...((this.operatorName !== 'demo' ||
              !endpoint.canRemoveOperator) && {
              operator_id: operatorDetails['operatorID'],
            }),
            nodeid: this.tasq.wellName,
            level:
              this.tasq && this.tasq.level
                ? this.tasq.level.toLowerCase()
                : 'well',
          },
        };

        gptModule.setGptQueryText(comment);
        if (endpoint.key == 'Recommendations') {
          this.isRecommendationLoaded = false;
          let stage = 'dev.';
          if (getConfigEnv('STAGE') == 'prod') {
            stage = '';
          }
          const url = `https://${stage}ai.tasq.io/ai/recommendations`;
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              query: comment,
              stream: endpoint.stream,
              ...(endpoint.useMetadata && {
                ...metaData,
              }),
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          completedRequests++;
          const reader = response.body.getReader();
          const textDecoder = new TextDecoder();
          let str = '';
          const processStream = ({ done, value }) => {
            if (queryTime === gptModule.queryTime) {
              if (done) {
                this.isRecommendationLoaded = true;
                return;
              }
              const chunk = textDecoder.decode(value);
              str += chunk;
              gptModule[`set${endpoint.key}`]({
                recommendations: str,
                queryTime: queryTime,
              });
              return reader.read().then(processStream);
            }
          };

          reader.read().then(processStream);
          return;
        } else {
          const response = await axiosClient.post(
            endpoint.url,
            {
              query: comment,
              stream: endpoint.stream,
              ...(endpoint.stream && { responseType: 'stream' }),
              ...(endpoint.useMetadata && {
                ...metaData,
              }),
            },
            {
              cancelToken: token,
            }
          );

          this.isPadLevelPage;
          gptModule[`set${endpoint.key}`]({ ...response.data, queryTime });
        }

        completedRequests++;
      } catch (error) {
        completedRequests++;
        console.error('Error fetching data for endpoint:', endpoint.url, error);
      }
    });
  }

  openResponsePopup(response) {
    if (
      response.eventKey === 'show-recommendation' &&
      !this.recommendation.length &&
      this.recommendation[0].length > 120
    ) {
      return;
    }
    this.showRcommendation = true;
  }

  get isSelectedWell() {
    return gptModule.selectedAiGroupWellsObj.find(
      (f) =>
        f.wellName === this.tasq.wellName &&
        (f.typeOfData === this.tasq.typeOfData || this.tasq.predictionType)
    );
  }

  removeWellFromList() {
    gptModule.updateGptAiGroupWells(this.tasq.wellName);
  }

  get tasqs() {
    return tasqsListModule.tasqs(null, null, null, true);
  }

  async updateAssignee() {
    try {
      await tasqActionsModule.mutateTasqReassign({
        comment: '',
        person: this.selectedAssignee[0].value,
        id: this.tasq.id,
      });

      this.$tasqAlert({
        title: 'Success',
        message: 'Successfully reassigned!',
        type: 'success',
      });
    } catch (error) {
      console.log(error);
    }
  }

  get usersTasqCount() {
    let userTasqCount = {};
    this.tasqs.forEach((tasq) => {
      if (tasq.username) {
        if (userTasqCount[tasq.username]) {
          userTasqCount[tasq.username] += 1;
        } else {
          userTasqCount[tasq.username] = 1;
        }
      }
    });

    return userTasqCount;
  }

  comment = 'Plunger is stuck';

  @Watch('latestLastAction')
  onLastActionChange(d) {
    console.log(d);
  }

  pointTime: any = '';
  endDate: any = '';
  body: any = '';

  get latestLastAction() {
    const data = gptModule.getLatestLastAction(this.tasq.wellName);
    if (data) {
      this.pointTime = data.point_time;
      this.body = data.body;

      if (data.type === 'Waiting On') {
        this.endDate = data.end_date;
      }
    }
    return data;
  }

  get reassignUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  get actionCountTooltipObject() {
    return {
      theme: 'tasq-tooltip',
      content: `${this.todaysActions} ${
        this.todaysActions > 1 ? 'actions' : 'action'
      } today`,
    };
  }

  get viewCountTooltipObject() {
    return {
      theme: 'tasq-tooltip',
      content: `${this.tasqViewsCount} ${
        this.tasqViewsCount > 1 ? 'views' : 'view'
      }`,
    };
  }

  mapLastActionColor(type) {
    const localType = type.toLowerCase();
    if (localType === 'on site fix') {
      return `bg-onsitefix`;
    } else if (localType === 'vendor') {
      return `bg-vendor`;
    } else if (localType === 'setpoint change' || localType === 'changepoint') {
      return `bg-setpointchange`;
    } else if (localType === 'changepoint') {
      return `bg-setpointchange`;
    } else if (localType === 'waiting on') {
      return `bg-waitingOn`;
    } else if (localType === 'midstream') {
      return `bg-midstream`;
    } else if (localType === 'alarm') {
      return `bg-alarm`;
    }
    return `bg-tasqNeutral300`;
  }

  lastActionTypesData = [
    'Waiting on',
    'Setpoint Change',
    'Vendor',
    'On site fix',
    'Alarm',
    'Midstream',
    'Other',
  ];

  get gptAnimateState() {
    return gptModule.animateState;
  }

  get wellTodoCurrentUserStatus() {
    const wellsAssigneStatus = gptModule.wellsAssigneeStatus;

    const wellStatus = wellsAssigneStatus.find(
      (w) => w.wellName === this.tasq.wellName
    );

    if (wellStatus) {
      return wellStatus;
    }

    return null;
  }

  async updateLastAction() {
    const data = {
      id: this.latestLastAction._id,
      body: this.body,

      type: this.latestLastAction.type,
      start_date: this.pointTime
        ? new Date(this.pointTime).toISOString()
        : this.pointTime.start_date || this.pointTime.date,
      end_date: this.endDate
        ? new Date(this.endDate).toISOString()
        : this.latestLastAction.end_date,
      tracking_id:
        this.latestLastAction.trackingId &&
        this.latestLastAction.trackingId !== 'NaN'
          ? this.latestLastAction.trackingId
          : await uniqid(),
    };
    const response = await axiosClient.post('/ai/vector', data);
    this.$tasqAlert({
      title: 'Success',
      message: 'Last action successfully updated!',
      type: 'success',
    });
  }

  formatDate(date) {
    const options: any = { month: 'short', day: 'numeric' };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString('en-US', options);

    return `${startFormatted}`;
  }

  openLink(wellName) {
    const url = `${window.location.origin}/Tasqs/${encodeURI(
      wellName
    )}?type=producing&view=tasq`;
    window.open(url, '_blank');
  }
}
